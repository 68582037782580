import Image from 'next/legacy/image';
import {IImageComponent} from '../../../lib/types/common';
import {nextImageLoader} from '../../../lib/utils/constants/PagesConstants';

const CustomImageComponent = (props: IImageComponent) => {
    const {
        layout,
        objectFit,
        src,
        alt,
        priority = undefined,
        height,
        width,
        objectPosition,
        className
    } = props;
    return (
        <Image
            loader={nextImageLoader}
            layout={layout}
            src={src}
            alt={alt ? alt : ""}
            priority={priority}
            height={height}
            width={width}
            objectPosition={objectPosition}
            objectFit={objectFit}
            // style={{
            //     objectFit: objectFit,
            //     objectPosition: objectPosition
            // }}
            className={className}
        />
    )
}

export default CustomImageComponent;