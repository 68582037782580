import Link from "next/link";
import { ILinkComponent } from "../../../lib/types/common";
import { isMobile } from "../../../lib/utils/constants/PagesConstants";
import React from "react";
const CustomLinkComponent = (props: React.PropsWithChildren<ILinkComponent>)=>{
    return(
        <Link
            href={props.href ? props.href : "#"}
            replace={props.replace}
            prefetch={props.prefetch || false}
            style={isMobile ? {...props.mobStyle,textDecoration:'none'} : {...props.desktopStyle,textDecoration:"none"}}
            className={props.className}
            scroll={props.scroll}
            passHref={props.passHref}
            id={props.id}
            locale={props.locale}
            key={props.className + "_" + props.href}
            onClick={props.onClick}
            suppressHydrationWarning
        >{props.children}</Link>
    )
}

export default CustomLinkComponent