import dynamic from "next/dynamic";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import BannerDownloadButtonComponentV2
    from "../../components/common/BannerDownloadButton/BannerDownloadButtonComponentV2";
import HeaderComponentV2 from "../../components/common/Header/HeaderComponentV2";
import WinzoBannerBottomComponentHomePageV2
    from "../../components/common/WinzoBannerBottomHomePage/WinzoBannerBottomHomePageComponentV2";
import CommonWithdrawalPartnerComponentV2
    from "../../components/noJSComponents/CommonWithdrawalPartner/CommonWithdrawalPartnerComponentV2";
import Analytics from "../../lib/analytics/Analytics";
import {
    ABTestingAnalytics,
    BannerDownloadClickEvent,
    FixedDownloadClickEvent,
    getABPageviewEvent,
    GetAppAFBannerDownloadClick,
    GetAppAFDownloadClick,
    GetAppBannerDownloadClick,
    GetAppDownloadClick,
    GetAppFBDownloadClick,
    GetAppFBFixedDownloadClick,
    GetAppFixedShareChatDownload,
    GetAppKwaiBannerDownloadClick,
    GetAppKwaiDownloadClick,
    GetAppPlayDownload,
    GetAppPlayShareChatDownload,
    GetAppTikTokBannerDownloadClick,
    GetAppTikTokDownloadClick,
    HeaderDownloadClick,
    RefBannerDownloadClick,
    RefFixedDownloadClick
} from "../../lib/analytics/AnalyticsEvents";
import useHandleHamburgerClick from "../../lib/customHooks/hamburgerClickHook";
import {LogEvent} from "../../lib/types/Analytics";
import {ICommonData, ILanguageToggleData} from "../../lib/types/common";
import {
    IAboutUsComponentProps,
    IBannerDownloadButtonComponentProps,
    IBannerNewComponentProps,
    ICommonWithdrawalPartnerComponentProps,
    IDownloadAppComponentProps,
    IFloatingDownloadButtonComponentProps,
    IFooterComponentProps,
    IFooterLinksSectionProps,
    IHeaderComponentProps,
    IHowToInstallAppComponentProps,
    IMemberOfComponentProps,
    IOfferComponentProps,
    IPageFAQComponentProps,
    IPopularGamesComponentProps,
    ISideDrawerComponentProps,
    IToastComponentProps,
    IUserRatingComponentProps,
    IWinzoBannerBottomComponentHomePageProps,
    IWinzoStoreOfferComponentProps,
    IWinzoTaglineProps,
    IWinzoUSPComponentProps,
    IWinzoVideoComponentProps,
    IWinzoWinnerComponentProps,
    IWithdrawalPartnersComponentProps
} from "../../lib/types/components";
import {IHomeApiData, IHomePageLanguageData} from "../../lib/types/home";
import {ABTestingData, BaseProps} from "../../lib/types/Misc.type";
import {
    APPSFLYER_WINZO_DOT_APP,
    APPSFLYER_WINZO_DOT_GAMES,
    appsFlyerLinkDownloadClick,
    appsFlyerLinkWithParam,
    appsFlyerURLGeneratorGetApp,
    appsFlyerURLGeneratorGetAppKwai,
    appsFlyerURLGeneratorOrganicPage
} from "../../lib/utils/constants/AppsFlyerConstants";
import {
    BANNER_DOWNLOAD_BUTTON_TYPE,
    FIXED_DOWNLOAD_BUTTON_TYPE,
    GET_APP_AF_URL,
    GET_APP_KWAI_URL,
    GET_APP_ONLINK_CODE,
    GET_APP_PLAY_URL,
    GET_APP_REF_URL,
    GET_APP_TIKTOK_URL,
    HEADER_DOWNLOAD_BUTTON_TYPE,
    HOME_PAGE_URL, INITIAL,
    UTM_FB,
    UTM_SHARE_CHAT,
    UTM_TRUECALLER
} from "../../lib/utils/constants/GlobalConstants";
import {
    currentPageURL,
    getDownloadvariant,
    getQueryStrings,
    isGetAppPage,
    isiOSDevice,
    isMobile,
    pagePath,
    setIsIOS
} from "../../lib/utils/constants/PagesConstants";

import Head from "next/head";
import FloatingDownloadButtonComponentV2
    from "../../components/common/FloatingDownloadButton/FloatingDownloadButtonComponentV2";
import {
    getWebsiteEnvironment,
    WINZO_DOT_APP,
    WINZO_DOT_GAMES_URL,
    WINZO_SPORT_URL
} from "../../lib/utils/constants/EnvironmentConstants";
import FloatingDownloadButtonComponentWithVideo
    from "../../components/common/FloatingDownloadButton/FloatingDownloadButtonComponentWithVideo";
import BannerNewComponentV2 from "../../components/common/BannerNew/BannerNewComponentV2";
import {showHowToInstallVideo} from "../../lib/utils/helpers/helper";
import {useRouter} from "next/router";
import {useDownloadContext} from "../../lib/context/CommonContext";

const OfferPopupComponentLazy = dynamic(() => import('../../components/common/OfferPopup/OfferPopupComponent'), {
    ssr: false
})

const ToastComponentLazy = dynamic(() => import('../../components/common/Toast/ToastComponent'), {
    ssr: false
})

const SideDrawerComponentLazy = dynamic(() => import('../../components/common/SideDrawer/SideDrawerComponent'), {
    ssr: true
})

const WinzoWinnerComponentV2Lazy = dynamic(() => import('../../components/noJSComponents/winzoWinner/WinzoWinnerComponentV2'), {
    ssr: true
});

const HowToInstallAppComponentV2Lazy = dynamic(() => import('../../components/noJSComponents/HowToInstallApp/HowToInstallAppComponentV2'), {
    ssr: true
});
const WinzoVideoComponentV2Lazy = dynamic(() => import("../../components/common/WinzoVideo/WinzoVideoComponentV2"), {
    ssr: false
});

const WinzoStoreOfferComponentLazy = dynamic(() => import("../../components/noJSComponents/WinzoStoreOffer/WinzoStoreOfferComponentV2"), {
    ssr: true
});

const WinzoUSPComponentV2Lazy = dynamic(() => import("../../components/common/WinzoUSP/WinzoUSPComponentV2"), {
    ssr: true
});

const PopularGamesComponentV2Lazy = dynamic(() => import("../../components/noJSComponents/PopularGames/PopularGamesComponentV2"), {
    ssr: true
});

const UserRatingComponentV2Lazy = dynamic(() => import("../../components/common/UserRatings/UserRatingComponentV2"), {
    ssr: true
});

const AboutUsComponentLazy = dynamic(() => import("../../components/noJSComponents/AboutUs/AboutUsComponent"), {
    ssr: true
});

const WithdrawalPartnersComponentV2Lazy = dynamic(() => import("../../components/common/WithdrawalPartners/WithdrawalPartnersComponentV2"), {
    ssr: true
});

const MemberOfComponentV2Lazy = dynamic(() => import("../../components/common/MemberOf/MemberOfComponentV2"), {
    ssr: true
});

const AboutUsComponentV2Lazy = dynamic(() => import("../../components/noJSComponents/AboutUs/AboutUsComponentV2"), {
    ssr: true
});

const DownloadAppComponentV2Lazy = dynamic(() => import("../../components/common/DownloadApp/DownloadAppComponentV2"), {
    ssr: true
});

const PageFAQComponentV2Lazy = dynamic(() => import("../../components/common/PageFAQ/PageFAQComponentV2"), {
    ssr: true
});

const FooterLinksSectionComponentV2Lazy = dynamic(() => import("../../components/common/FooterLinks/FooterLinksSectionV2"), {
    ssr: true
});

const FooterComponentV2Lazy = dynamic(() => import("../../components/common/Footer/FooterComponentV2"), {
    ssr: true
});

interface Props extends BaseProps {
    apiData: IHomeApiData
    autoScrollToElement: () => void
    url: string
    installStepContainer: React.RefObject<HTMLElement>
    downloadUrl: string
    androidUrl: string
    abTestingData: ABTestingData
}

function IndexPageIndiaV2(props: Props) {
    // All get-app pages boolean
    const isGetAppRef: boolean = currentPageURL === GET_APP_REF_URL;
    const isGetApp: boolean = isGetAppPage(props.apiData.pageName);
    const isGetAppAf: boolean = currentPageURL === GET_APP_AF_URL;
    const isGetAppPlay: boolean = currentPageURL === GET_APP_PLAY_URL;
    const isHomePage: boolean = currentPageURL === HOME_PAGE_URL;
    const isGetAppKwai: boolean = props.apiData.pageName === GET_APP_KWAI_URL;
    const isGetAppTiktok: boolean = props.apiData.pageName === GET_APP_TIKTOK_URL;
    // variable containing api data from getStaticProps
    const content: IHomePageLanguageData = props.apiData;
    const commonData: ICommonData = props.apiData.commonData;
    const languageToggleData: ILanguageToggleData = props.apiData.languageToggleData;
    const router = useRouter();
    const {clickApkUrl} = useDownloadContext()
    //TODO : REMOVE THESE CHECKS AFTER text3 AND text4 ARE MOVED TO COMMON.
    if(commonData.text3){
        content.bannerHome.text3 = commonData.text3
    }
    if(commonData.text4){
        content.bannerHome.text4 = commonData.text4
    }
    // All react States related to page
    const [isApkDownloading, setIsApkDownloading] = useState<boolean>(false);
    const apkUrl: string = props.downloadUrl;
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [appsFlyerURL, setAppsFlyerURL] = useState<string>('');
    const [appsflyerDownloadClickEventUrl, setAppsflyerDownloadClickEventUrl] = useState<string>('');
    const [menuOpen, handleHamburgerClick] = useHandleHamburgerClick();
    const [trueCallerPixelImage, setTrueCallerPixelImage] = useState<string>(
        "https://pixel.truecaller.com/v0/ty?&type=web&subtype=engage&event=visit&bundleid=com.winzo.gold&campaign=2795517833_5575134876_0_0"
    );
    const isApkDownloadingRef: React.MutableRefObject<boolean> = useRef(isApkDownloading);
    isApkDownloadingRef.current = isApkDownloading;
    const showOfferPopup: boolean = props.apiData.showOfferPopup;

    //All analytics handling function
    const handleAnalytics = (): void => {
        // A/B Testing pageview  
        if (props.apiData?.variant) {
            Analytics.getInstance().logEvent(getABPageviewEvent(props.apiData?.variant));
        }
    }

    const sendAnalytics = (type: string) => {
        if (isGetAppAf) {
            const oneLinkUrl = appsFlyerLinkDownloadClick(
                GET_APP_ONLINK_CODE,
                pagePath()
            );
            setAppsFlyerURL(oneLinkUrl);
        }
        let event: LogEvent = {
            category: "",
            action: "",
            label: "",
            fbEvent: "",
            value: 0,
        };

        if (isGetAppKwai && window.kwaiq) {
            window.kwaiq.instance('510535249999962171').track('completeRegistration')
        }

        if (type === HEADER_DOWNLOAD_BUTTON_TYPE) {
            event = HeaderDownloadClick;
        } else if (type === BANNER_DOWNLOAD_BUTTON_TYPE) {
            event =
                isGetApp || isGetAppPlay
                    ?  getQueryStrings().utm === UTM_SHARE_CHAT
                            ? GetAppPlayShareChatDownload
                            : getQueryStrings().utm === UTM_FB
                                ? GetAppFBDownloadClick
                                : isGetAppPlay
                                    ? GetAppPlayDownload
                                    : GetAppBannerDownloadClick
                    : isGetAppAf
                        ? GetAppAFBannerDownloadClick
                        : isGetAppRef
                            ? RefBannerDownloadClick
                            : isGetAppKwai
                                ? GetAppKwaiBannerDownloadClick
                                : isGetAppTiktok
                                    ? GetAppTikTokBannerDownloadClick
                                    : BannerDownloadClickEvent;
        } else if (type === FIXED_DOWNLOAD_BUTTON_TYPE) {
            event = isGetApp
                ? getQueryStrings().utm === UTM_SHARE_CHAT
                        ? GetAppFixedShareChatDownload
                        : getQueryStrings().utm === UTM_FB
                            ? GetAppFBFixedDownloadClick
                            : GetAppDownloadClick
                : isGetAppAf
                    ? GetAppAFDownloadClick
                    : isGetAppRef
                        ? RefFixedDownloadClick
                        : isGetAppKwai
                            ? GetAppKwaiDownloadClick
                            : isGetAppTiktok
                                ? GetAppTikTokDownloadClick
                                : FixedDownloadClickEvent;
        }

        if (isGetApp && getQueryStrings().utm === UTM_TRUECALLER) {
            setTrueCallerPixelImage(
                "https://pixel.truecaller.com/v0/ty?&type=web&subtype=engage&event=download&bundleid=com.winzo.gold&campaign=2795517833_5575134876_0_0"
            );
        }

        Analytics.getInstance().logEvent(event);
        if (props.apiData?.variant) {
            event = ABTestingAnalytics(event, props.apiData?.variant)
            Analytics.getInstance().logEvent(event);
        }
    }

    const showHowToInstall = () => {
        if (isiOSDevice) {
            return false
        }
        if ((isGetApp || isGetAppAf || isGetAppRef) && !isMobile) {
            return false
        } else {
            return true
        }
    }

    //AppsFlyer URL generated here for get-app page
    useEffect((): void => {
        handleAnalytics();
        setIsIOS();
    }, []);

    useEffect(() => {
        if (isApkDownloading) {
            console.log("AppsFlyer", "fire Click", appsflyerDownloadClickEventUrl)
            setAppsFlyerURL(appsflyerDownloadClickEventUrl)
        }
    }, [isApkDownloading])

    useEffect(() => {
        apkUrl && appFlyerEventHandle(apkUrl)
    }, [apkUrl, props.apiData.locale]);

    useEffect(() => {
        clickApkUrl && appFlyerEventHandle(clickApkUrl)
    }, [clickApkUrl]);

    const appFlyerEventHandle = (downloadUrl: string) => {
        const architecture = getQueryStrings(downloadUrl).arch;
        if (architecture && architecture !== INITIAL) {
            let url: string | null = null
            if (isHomePage) {
                if (getWebsiteEnvironment().baseUrl === WINZO_DOT_GAMES_URL) {
                    setAppsflyerDownloadClickEventUrl(appsFlyerURLGeneratorGetApp(architecture, APPSFLYER_WINZO_DOT_GAMES.GET_APP_ONELINK));
                } else if (getWebsiteEnvironment().baseUrl === WINZO_DOT_APP) {
                    setAppsflyerDownloadClickEventUrl(appsFlyerURLGeneratorGetApp(architecture, APPSFLYER_WINZO_DOT_APP.GET_APP_ONELINK));
                } else if (getWebsiteEnvironment().baseUrl === WINZO_SPORT_URL) {
                    url = appsFlyerLinkWithParam();
                } else {
                    setAppsflyerDownloadClickEventUrl(appsFlyerURLGeneratorOrganicPage(
                        props.apiData.locale,
                        architecture,
                        getDownloadvariant()
                    ))
                }
                url && setAppsFlyerURL(url);
            } else if (isGetApp) {
                if (getWebsiteEnvironment().baseUrl === WINZO_DOT_GAMES_URL) {
                    setAppsflyerDownloadClickEventUrl(appsFlyerURLGeneratorGetApp(architecture, APPSFLYER_WINZO_DOT_GAMES.GET_APP_ONELINK));
                } else {
                    setAppsflyerDownloadClickEventUrl(appsFlyerURLGeneratorGetApp(architecture))
                }
            } else if (isGetAppKwai) {
                window.kwaiq && window.kwaiq.instance('510535249999962171').track('contentView');
                setAppsflyerDownloadClickEventUrl(appsFlyerURLGeneratorGetAppKwai(architecture))
            }
        }
    }

    // All components' props' values assigned 
    const toastCompProps: IToastComponentProps = {
        show: isToastOpen,
        setShow: setIsToastOpen,
        internetError: commonData?.toastComponent.internetError
    }
    let pageName = props.apiData.pageName || "";
    const floatingDownloadBtnCompProps: IFloatingDownloadButtonComponentProps = {
        apkUrl: apkUrl,
        isApkDownloading: isApkDownloading,
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        blinkTextDownload: commonData.blinkTextDownloadButton,
        blinkTextDownload2: commonData.blinkTextDownloadButton2,
        fixedDownloadButtonData: commonData.fixedDownloadButton,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        sendAnalytics: sendAnalytics,
        locale: props.apiData.locale,
        //TODO
        howToInstallVideo: commonData?.howToInstallVideo || commonData?.howToInstall?.howToInstallVideo,
        howToInstallThumbnail: commonData?.howToInstallThumbnail || commonData?.howToInstall?.howToInstallThumbnail,
        downloadingAnimation: commonData.downloadingAnimation,
        isHomePage: isHomePage,
        abTestingData: props.abTestingData,
        appVersions: props.appVersions,
        pageName,
        setAppsFlyerURL
    }

    const sideDrawerCompProps: ISideDrawerComponentProps = {
        handleHamburgerClick: handleHamburgerClick,
        menuOpen: menuOpen,
        navigationDataLinks: commonData.navigationDataLinks
    }
    const headerCompProps: IHeaderComponentProps = {
        specialData: commonData.specialData,
        hideHamburger: content.hideHamburger,
        fixedDownloadButtonData: commonData.fixedDownloadButton,
        handleHamburgerClick: handleHamburgerClick,
        menuOpen: menuOpen,
        downloadLink: apkUrl,
        isApkDownloading: isApkDownloading,
        sendAnalytics: sendAnalytics,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        languageToggleData: languageToggleData,
        showToggle: props.apiData.showLanguageToggle,
        locale: props.apiData.locale,
        countrySwitcherText: commonData.multiRegional?.countrySwitcherText,
        abTestingData: props.abTestingData,
        appVersions: props.appVersions,
        pageName,
        setAppsFlyerURL
    }
    const bannerNewCompProps: IBannerNewComponentProps = {
        bannerHomeData: content.bannerHome,
        smsSentModalData: commonData.smsSentModal,
        locale: props.apiData.locale,
        setIsToastOpen: setIsToastOpen,
        smsVariablesTemplate: commonData.smsVariablesTemplate,
        mobileNumber: commonData.mobileNumber,
        disableAppDownload: commonData.fixedDownloadButton.disabled || false
    }
    const bannerDownloadBtnCompProps: IBannerDownloadButtonComponentProps = {
        apkUrl: apkUrl,
        isApkDownloading: isApkDownloading,
        fixedDownloadButtonData: commonData.fixedDownloadButton,
        sendAnalytics: sendAnalytics,
        setIsToastOpen: setIsToastOpen,
        setIsApkDownloading: setIsApkDownloading,
        blinkTextDownloadButton: commonData.blinkTextDownloadButton,
        iosCtaData: props.apiData.iosCtaData,
        abTestingData: props.abTestingData,
        appVersions: props.appVersions,
        pageName,
        setAppsFlyerURL
    }
    const commonWithdrawalPartnerCompProps: ICommonWithdrawalPartnerComponentProps = {
        withdrawalPartnerData: commonData.withdrawalPartner,
        isInBanner: isMobile
    }
    const winzoBannerBottomCompProps: IWinzoBannerBottomComponentHomePageProps = {
        bannerBottomData: commonData.bannerBottom,
        withdrawalPartnerData: commonData.withdrawalPartner
    }
    const winzoTaglineProps: IWinzoTaglineProps = {
        WinzoTagLineData: content.WinzoTagLine,
        winzoTagLineIos: commonData.winzoTagLineIos,
        isWithdrawalPartnerData: !!commonData.withdrawalPartner,
    }
    const popularGamesCompProps: IPopularGamesComponentProps = {
        popularGamesData: content.popularGamesV2 || content.popularGames || undefined,
        isTextClickable: true,
        winzoTagLineProps: winzoTaglineProps
    }
    const winzoWinnerCompProps: IWinzoWinnerComponentProps = {
        winzoWinnerData: commonData.winzoWinner
    }
    const userRatingCompProps: IUserRatingComponentProps = {
        customerReviewData: commonData.customerReview,
        winzoWinnerCompProps: winzoWinnerCompProps
    }
    const howToInstallCompProps: IHowToInstallAppComponentProps = {
        howToInstallData: commonData.howToInstall,
        installStepContainer: props.installStepContainer
    }
    const winzoUSPCompProps: IWinzoUSPComponentProps = {
        winzoUSPData: content.winzoUSP
    }
    const memberOfCompProps: IMemberOfComponentProps = {
        membersData: commonData.members
    }
    const winzoStoreOfferCompProps: IWinzoStoreOfferComponentProps = {
        winzoStoreData: content.winzoStore
    }
    const withdrawalPartnersCompProps: IWithdrawalPartnersComponentProps = {
        withdrawalPartnerData: commonData.withdrawalPartner,
        isGetApp: isGetAppAf || isGetAppRef
    }
    const winzoVideoCompProps: IWinzoVideoComponentProps = {
        videosData: content.videos,
    }
    const aboutUsCompProps: IAboutUsComponentProps = {
        aboutUsData: content.aboutUs,
        isMobile: isMobile
    }

    const disclaimerData: IAboutUsComponentProps = {
        ...commonData.disclaimerData,
        isMobile: isMobile,
        isGetApp
    };
    const downloadAppCompProps: IDownloadAppComponentProps = {
        downloadAppData: content.downloadApp,
        androidUrl: props.androidUrl,
        locale: props.apiData.locale
    }
    const pageFAQCompProps: IPageFAQComponentProps = {
        pageFAQData: content.pageFAQ,
        showFaqSchema: false
    }
    const footerCompProps: IFooterComponentProps = {
        downloadLink: apkUrl,
        footerData: commonData.footer,
        membersData: commonData.members,
        withdrawalPartnerData: commonData.withdrawalPartner
    }

    const footerLinksSection: IFooterLinksSectionProps = {
        footerData: commonData.footer
    }

    const offerPopupProps: IOfferComponentProps = {
        apkUrl: apkUrl,
        showOfferPopup: showOfferPopup,
        isApkDownloadingRef: isApkDownloadingRef,
        locale: props.apiData.locale
    }

    return (
        <>
            <Head>
                {(isGetAppRef || isGetApp || isGetAppAf || isGetAppPlay) && (
                    <meta name="robots" content="noindex, nofollow"/>
                )}
            </Head>
            <img
                height={1}
                width={1}
                style={{display: "none", position: "absolute"}}
                src={appsFlyerURL}
                alt="online social gaming app"
            />
            {isGetApp && getQueryStrings().utm === UTM_TRUECALLER && (
                <img
                    height={1}
                    width={1}
                    style={{display: "none", position: "absolute"}}
                    src={trueCallerPixelImage}
                    alt="true caller"
                />
            )}
            <OfferPopupComponentLazy {...offerPopupProps} />
            <ToastComponentLazy {...toastCompProps} />
            {showHowToInstallVideo(commonData, props.apiData.locale, router.query["utm"]) ? (
                <FloatingDownloadButtonComponentWithVideo
                    {...floatingDownloadBtnCompProps}
                    isMute={isGetAppRef}
                />
            ) : (
                <FloatingDownloadButtonComponentV2
                    {...floatingDownloadBtnCompProps}
                />
            )}
            <SideDrawerComponentLazy {...sideDrawerCompProps} />
            <main>
                <HeaderComponentV2 {...headerCompProps} />
                <BannerNewComponentV2 {...bannerNewCompProps} />
                <BannerDownloadButtonComponentV2
                    {...bannerDownloadBtnCompProps}
                />
                <CommonWithdrawalPartnerComponentV2
                    {...commonWithdrawalPartnerCompProps}
                />
                <WinzoBannerBottomComponentHomePageV2
                    {...winzoBannerBottomCompProps}
                />
                <WinzoUSPComponentV2Lazy {...winzoUSPCompProps} />
                <PopularGamesComponentV2Lazy {...popularGamesCompProps} />
                <WinzoWinnerComponentV2Lazy {...winzoWinnerCompProps} />
                {!isiOSDevice && (
                    <div>
                        <UserRatingComponentV2Lazy {...userRatingCompProps} />
                    </div>
                )}
                {showHowToInstall() && (
                    <HowToInstallAppComponentV2Lazy
                        {...howToInstallCompProps}
                    />
                )}
                {commonData.disclaimerData && <AboutUsComponentLazy {...disclaimerData} />}
                {isGetAppAf ? (
                    <></>
                ) : (
                    <WinzoStoreOfferComponentLazy {...winzoStoreOfferCompProps} />
                )}
                <WithdrawalPartnersComponentV2Lazy
                    {...withdrawalPartnersCompProps}
                />
                {isHomePage && <MemberOfComponentV2Lazy {...memberOfCompProps} />}
                <WinzoVideoComponentV2Lazy {...winzoVideoCompProps} />
                {isGetAppAf ? (
                    <></>
                ) : (
                    <AboutUsComponentV2Lazy {...aboutUsCompProps} />
                )}
                <DownloadAppComponentV2Lazy {...downloadAppCompProps} />
                {/* <PageFAQComponent {...pageFAQCompProps} /> */}
                <div>
                    {isGetAppAf ? (
                        <></>
                    ) : (
                        <PageFAQComponentV2Lazy {...pageFAQCompProps} />
                    )}
                </div>
                <div>
                    {isGetAppAf || isGetAppRef ? (
                        <></>
                    ) : (
                        <FooterLinksSectionComponentV2Lazy
                            {...footerLinksSection}
                        />
                    )}
                </div>
                <FooterComponentV2Lazy {...footerCompProps} />
            </main>
        </>
    );
}

export default IndexPageIndiaV2;