import React, {useEffect, useState} from "react";
import {DEVICE_MODEL, INITIAL, NOT_FOUND, VariantA, VariantB,} from "../lib/utils/constants/GlobalConstants";
import {
    getAppleAppUrl,
    getCookie,
    getCountryFromLocale,
    getDeviceArchitecture,
    getDownloadRedirectABTestingData,
    getIntentUrlWithFallback,
    isiOSDevice,
    setDownloadVariant,
    updateAppUrls,
} from "../lib/utils/constants/PagesConstants";
import {BrandModelData} from "../lib/types/Misc.type";
import Analytics from "../lib/analytics/Analytics";
import {DownloadURLWithVariant} from "../lib/analytics/AnalyticsEvents";

function GetAppVersion(WrappedComponent) {
    return function WithGetAppVersion(props) {

        const pageName: string = props.apiData.pageName;
        const locale: string = props.apiData.locale;
        const appVersions = props.appVersions;

        const [apkUrl, setApkUrl] = useState(updateAppUrls(INITIAL, locale, appVersions, pageName))
        const [deviceModel, setDeviceModel] = useState<string | undefined>(undefined)

        useEffect(() => {
            if (!deviceModel) {
                // @ts-ignore
                if (getCookie(DEVICE_MODEL) === NOT_FOUND && typeof navigator.userAgentData !== "undefined") {
                    // @ts-ignore
                    navigator.userAgentData
                        .getHighEntropyValues(["model"])
                        .then((values) => {
                            setDeviceModel(values.model)
                        });
                } else {
                    setDeviceModel(getCookie(DEVICE_MODEL))
                }
            }
        }, [])

        useEffect(() => {
            if (deviceModel) {
                let deviceArchitecture = getDeviceArchitecture(
                    props.deviceArchitectureMapping,
                    deviceModel || NOT_FOUND
                )

                //download url based on device brand
                let apkChanged = false;
                if (downloadRedirectData && downloadRedirectData.variant === VariantB && downloadRedirectData.executeTesting.includes(pageName)) {
                    const brands = downloadRedirectData.brands;
                    const brandModelData: BrandModelData = props.brandModelData;
                    Object.entries(brandModelData).forEach(([key, value]) => {
                        if (brands[key]) {
                            for (const item of value) {
                                if (deviceModel === item) {
                                    const fallBackUrl = updateAppUrls(deviceArchitecture, locale, appVersions, pageName);
                                    const downloadUrlWithFallback = getIntentUrlWithFallback(brands[key], fallBackUrl);
                                    setApkUrl(downloadUrlWithFallback)
                                    apkChanged = true;
                                    break
                                }
                            }
                        }
                    })
                }

                if (!apkChanged) {
                    setApkUrl(updateAppUrls(deviceArchitecture, locale, appVersions, pageName))
                    Analytics.getInstance().logEvent(DownloadURLWithVariant(VariantA))
                } else {
                    Analytics.getInstance().logEvent(DownloadURLWithVariant(VariantB))
                    setDownloadVariant(VariantB);
                }
            }
        }, [deviceModel]);

        let installStepContainer = React.useRef<HTMLElement>(null);
        // todo please update global_app_url once global ios app in live
        let iosUrl: string = getAppleAppUrl(getCountryFromLocale(props.apiData.locale))

        // ab testing for brand based url devices
        let downloadRedirectData = getDownloadRedirectABTestingData(props.abTestingData);
        // auto scroll to how-to-install section function
        const autoScrollToElement = (): void => {
            document.getElementById("id_howToInstall")?.scrollIntoView()
        }

        const getApkUrlFromDeviceType = () => {
            if (props.apiData.commonData.fixedDownloadButton.disabled) {
                return "#"
            }

            if (isiOSDevice)
                return iosUrl;
            return apkUrl;
        }

        return <WrappedComponent
            autoScrollToElement={autoScrollToElement}
            installStepContainer={installStepContainer}
            downloadUrl={getApkUrlFromDeviceType()}
            androidUrl={apkUrl}
            {...props}
        />;
    }
}

export default GetAppVersion;